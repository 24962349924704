/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CurrencyIsoCode {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
}

export enum FinancingType {
  JUNIOR_LOAN = 'JUNIOR_LOAN',
  SENIOR_LOAN = 'SENIOR_LOAN',
  WHOLE_LOAN = 'WHOLE_LOAN',
}

export enum Folder {
  CASHFLOW_SCHEDULE = 'CASHFLOW_SCHEDULE',
  CONTRACTUAL_DOCUMENTS = 'CONTRACTUAL_DOCUMENTS',
  IMAGES = 'IMAGES',
  INVESTMENT_DOCUMENTS = 'INVESTMENT_DOCUMENTS',
  INVESTMENT_MEMORANDUM = 'INVESTMENT_MEMORANDUM',
  KEY_INFORMATION_DOCUMENTS_TEST_INVESTMENT = 'KEY_INFORMATION_DOCUMENTS_TEST_INVESTMENT',
  LEGAL_DOCUMENTS = 'LEGAL_DOCUMENTS',
  PORTRAIT_IMAGE = 'PORTRAIT_IMAGE',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  PREVIEW_IMAGE = 'PREVIEW_IMAGE',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  QUARTERLY_REPORTS = 'QUARTERLY_REPORTS',
  SIGNED_PURCHASE_AGREEMENT = 'SIGNED_PURCHASE_AGREEMENT',
  STEUERBESCHEINIGUNG = 'STEUERBESCHEINIGUNG',
  STEUERMERKMALE = 'STEUERMERKMALE',
  TEASER = 'TEASER',
}

export enum IdentCaseStatus {
  CREATED = 'CREATED',
  PERSON_ASSIGNED = 'PERSON_ASSIGNED',
  PERSON_IDENTIFIED = 'PERSON_IDENTIFIED',
  PERSON_NOT_IDENTIFIED_ON_TIME = 'PERSON_NOT_IDENTIFIED_ON_TIME',
}

export enum IdentCaseTransactionStatus {
  ABORTED = 'ABORTED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  FAILED_TO_START = 'FAILED_TO_START',
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
}

export enum IdentCaseTransactionType {
  IDNOW_AUTO = 'IDNOW_AUTO',
  IDNOW_QES = 'IDNOW_QES',
  IDNOW_VIDEO = 'IDNOW_VIDEO',
  LIONWARE_POSTIDENT = 'LIONWARE_POSTIDENT',
  LIONWARE_POSTIDENT_QES = 'LIONWARE_POSTIDENT_QES',
  MANUAL = 'MANUAL',
}

export enum IdentCaseType {
  AML = 'AML',
  EXTERNAL = 'EXTERNAL',
  LINUS = 'LINUS',
  QES_AML = 'QES_AML',
}

export enum InvestmentRequestStatus {
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  INVESTED = 'INVESTED',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
  SUBMITTED = 'SUBMITTED',
}

export enum Language {
  DE = 'DE',
  EN = 'EN',
}

export enum MarketplaceUserFallbackRegistrationStatus {
  CONTACT_DETAILS_WITH_TERMS_AND_NDA_SUBMITTED = 'CONTACT_DETAILS_WITH_TERMS_AND_NDA_SUBMITTED',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
}

export enum MarketplaceUserInvitationStatus {
  CONTACT_DETAILS_WITH_TERMS_AND_NDA_SUBMITTED = 'CONTACT_DETAILS_WITH_TERMS_AND_NDA_SUBMITTED',
  INVITED_VIA_SALESFORCE = 'INVITED_VIA_SALESFORCE',
  PASSWORD_SET_AND_EMAIL_VERIFIED = 'PASSWORD_SET_AND_EMAIL_VERIFIED',
}

export enum MarketplaceUserRegistrationStatus {
  ACCOUNT_DETAILS_SUBMITTED = 'ACCOUNT_DETAILS_SUBMITTED',
  CALENDLY_SCHEDULED = 'CALENDLY_SCHEDULED',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  LOGIN_CREDENTIALS_PROVIDED = 'LOGIN_CREDENTIALS_PROVIDED',
  VERIFICATION_EMAIL_REQUESTED = 'VERIFICATION_EMAIL_REQUESTED',
}

export enum MarketplaceUserSource {
  AUTH0_REGISTRATION_SCREEN = 'AUTH0_REGISTRATION_SCREEN',
  INVITATION = 'INVITATION',
  LEGACY_PLATFORM = 'LEGACY_PLATFORM',
  REGISTRATION = 'REGISTRATION',
}

export enum Platform {
  EU = 'EU',
  UK = 'UK',
}

export enum ProductType {
  CORE = 'CORE',
  DEAL_BY_DEAL = 'DEAL_BY_DEAL',
  DEBT_FUND = 'DEBT_FUND',
  MANAGED_PORTFOLIO = 'MANAGED_PORTFOLIO',
  PARTNER_FUND = 'PARTNER_FUND',
  PARTNER_FUND_LARAMAR = 'PARTNER_FUND_LARAMAR',
  TEST_INVESTMENT = 'TEST_INVESTMENT',
  TREUHAND = 'TREUHAND',
}

export enum RepaymentType {
  BULLET = 'BULLET',
  PAID_ANNUALLY = 'PAID_ANNUALLY',
  QUARTERLY = 'QUARTERLY',
  SPECIAL = 'SPECIAL',
}

export enum SalesforceLanguage {
  DE = 'DE',
  EN = 'EN',
}

export enum SalesforcePlatformAccess {
  CORE = 'CORE',
  FULL_PLATFORM = 'FULL_PLATFORM',
  FULL_PLATFORM_PLUS_DEBT_FUND = 'FULL_PLATFORM_PLUS_DEBT_FUND',
}

export enum SalesforceSalutation {
  MR = 'MR',
  MS = 'MS',
}

export enum SalesforceUserStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  JOIN = 'JOIN',
  NOUSER = 'NOUSER',
  PREVIEW = 'PREVIEW',
}

export enum StageName {
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  AWAITING_SIGNED_DOCUMENTS = 'AWAITING_SIGNED_DOCUMENTS',
  AWARE = 'AWARE',
  CAPITAL_CALL = 'CAPITAL_CALL',
  CLOSED_LOST = 'CLOSED_LOST',
  CLOSING_AND_BILLING = 'CLOSING_AND_BILLING',
  COMPLIANCE_APPROVAL = 'COMPLIANCE_APPROVAL',
  COUNTERSIGN_AND_CAPITAL_CALL = 'COUNTERSIGN_AND_CAPITAL_CALL',
  DUE_DILIGENCE_AND_CONTRACTS = 'DUE_DILIGENCE_AND_CONTRACTS',
  IC_RECOMMENDATION = 'IC_RECOMMENDATION',
  INDICATIVE_TERMS = 'INDICATIVE_TERMS',
  INTERESTED = 'INTERESTED',
  INVESTED = 'INVESTED',
  NEW = 'NEW',
  OTHER = 'OTHER',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
  PRE_APPROVAL_IC = 'PRE_APPROVAL_IC',
  PRE_APPROVAL_INVESTMENT = 'PRE_APPROVAL_INVESTMENT',
  PRE_APPROVAL_RISK_MANAGEMENT = 'PRE_APPROVAL_RISK_MANAGEMENT',
  REPAID = 'REPAID',
  REPORTING_AND_MONITORING = 'REPORTING_AND_MONITORING',
  RISK_MANAGEMENT = 'RISK_MANAGEMENT',
  SEND_ACCESSION_DOCUMENTS = 'SEND_ACCESSION_DOCUMENTS',
  SIGNING = 'SIGNING',
  TERM_SHEET = 'TERM_SHEET',
  UNAWARE = 'UNAWARE',
  VOTUM_FOUND_MANAGEMENT = 'VOTUM_FOUND_MANAGEMENT',
}

export enum StatusOnWebsite {
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  HIDDEN = 'HIDDEN',
  LIVE = 'LIVE',
  OFFLINE = 'OFFLINE',
  REPAID = 'REPAID',
}

export enum UkInvestorClassification {
  CERTIFIED_SOPHISTICATED = 'CERTIFIED_SOPHISTICATED',
  HIGH_NET_WORTH = 'HIGH_NET_WORTH',
  PROFESSIONAL_CLIENT = 'PROFESSIONAL_CLIENT',
  SELF_CERTIFIED_SOPHISTICATED = 'SELF_CERTIFIED_SOPHISTICATED',
}

export interface AddressDataInput {
  street: string
  zip: string
  city: string
  country?: string | null
}

export interface IdentCaseFilter {
  ids?: string[] | null
  types?: IdentCaseType[] | null
  personIds?: string[] | null
}

export interface PersonDataInput {
  firstName: string
  lastName: string
  email: string
  birthDate: any
  birthplace?: string | null
  phone?: string | null
  salutation?: string | null
  title?: string | null
  citizenship?: string | null
  address?: AddressDataInput | null
}

export interface RegistrationAccountDetailsInput {
  salutation: SalesforceSalutation
  firstName: string
  lastName: string
  phone: string
  company?: string | null
  language?: SalesforceLanguage | null
  referredById?: string | null
}

export interface RegistrationFormDataInput {
  language: Language
  platform: Platform
  termsAccepted: boolean
  ndaAccepted: boolean
  metadata?: RegistrationFormDataInputMetadata | null
}

export interface RegistrationFormDataInputMetadata {
  utmFirstClick?: string | null
  utmLastClick?: string | null
  sessionCount?: number | null
  ga?: string | null
  gid?: string | null
  landingPage?: string | null
}

export interface SupportRequestGraphQLInput {
  message: string
}

export interface UpdateAccountDetailsGraphQLInput {
  salutation?: SalesforceSalutation | null
  firstName?: string | null
  lastName?: string | null
  company?: string | null
  phone?: string | null
  referredById?: string | null
  newsletter?: boolean | null
  language?: SalesforceLanguage | null
  hasOptedOutOfEmail?: boolean | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================

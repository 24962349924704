import React, { lazy, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import AppProviders from 'AppProviders'
import { ActionsHandler } from 'components/ActionsHandler'
import { Loading } from 'components/Loading'
import { PrivateRoute } from 'components/PrivateRoute'
import { RedirectHandler } from 'components/RedirectHandler'
import { CheckoutProvider } from 'contexts/CheckoutProvider'
import { createBrowserHistory } from 'history'
import { useLanguageSwitch } from 'hooks/useLanguageSwitch'
import { Login } from 'pages/Login'
import { Logout } from 'pages/Logout'
import { Route, Routes, Navigate } from 'react-router-dom'
import { init as utmInit } from 'services/utm'
import { createGlobalStyle } from 'styled-components'
import { routes, isDevelop } from 'utils/constants'
// TODO: this is a tech debt. We have to replace these components in order to avoid this import
import '@reach/listbox/styles.css'
import '@reach/menu-button/styles.css'
import '@reach/dialog/styles.css'
import { lazyRetry } from 'utils/misc'

export const history = createBrowserHistory()

const AuthCallbackPage = lazy(() =>
  lazyRetry('AuthCallback', () => import(/* webpackChunkName: "AuthCallback" */ './pages/AuthCallback')).then(
    ({ AuthCallback }) => ({
      default: AuthCallback,
    })
  )
)

const ProjectOverviewPage = lazy(() =>
  lazyRetry(
    'ProjectOverview',
    () => import(/* webpackChunkName: "ProjectOverview" */ './pages/ProjectOverview')
  ).then(({ ProjectOverview }) => ({
    default: ProjectOverview,
  }))
)

const BuilderDev = lazy(() =>
  lazyRetry('BuilderDev', () => import(/* webpackChunkName: "Builder" */ './pages/Builder')).then(
    ({ BuilderDev }) => ({
      default: BuilderDev,
    })
  )
)

const ProductDetail = lazy(() =>
  lazyRetry('ProductDetail', () => import(/* webpackChunkName: "Builder" */ './pages/Builder')).then(
    ({ ProductDetail }) => ({
      default: ProductDetail,
    })
  )
)

const IdVerificationPage = lazy(() =>
  lazyRetry(
    'IdVerification',
    () => import(/* webpackChunkName: "IdVerificationPage" */ './pages/IdVerification')
  ).then(({ IdVerification }) => ({
    default: IdVerification,
  }))
)

const PortfolioPage = lazy(() =>
  lazyRetry('Portfolio', () => import(/* webpackChunkName: "PortfolioPage" */ './pages/Portfolio')).then(
    ({ Portfolio }) => ({
      default: Portfolio,
    })
  )
)

const ContactPage = lazy(() =>
  lazyRetry('Contact', () => import(/* webpackChunkName: "ContactPage" */ './pages/Contact')).then(
    ({ Contact }) => ({
      default: Contact,
    })
  )
)

const ImprintPage = lazy(() =>
  lazyRetry('Imprint', () => import(/* webpackChunkName: "ImprintPage" */ './pages/Imprint')).then(
    ({ Imprint }) => ({
      default: Imprint,
    })
  )
)

const UserAccountPage = lazy(() =>
  lazyRetry('UserAccount', () => import(/* webpackChunkName: "UserAccount" */ './pages/UserAccount')).then(
    ({ UserAccount }) => ({
      default: UserAccount,
    })
  )
)

const CheckoutPage = lazy(() =>
  lazyRetry('Checkout', () => import(/* webpackChunkName: "CheckoutPage" */ './pages/Checkout')).then(
    ({ Checkout }) => ({
      default: Checkout,
    })
  )
)

const RegistrationPage = lazy(() =>
  lazyRetry('Registration', () => import(/* webpackChunkName: "Registration" */ './pages/Registration')).then(
    ({ Registration }) => ({
      default: Registration,
    })
  )
)

const ClassificationPage = lazy(() =>
  lazyRetry(
    'Classification',
    () => import(/* webpackChunkName: "Classification" */ './pages/Classification')
  ).then(({ Classification }) => ({
    default: Classification,
  }))
)

const ContactDetailsPage = lazy(() =>
  lazyRetry(
    'ContactDetails',
    () => import(/* webpackChunkName: "ContactDetails" */ './pages/ContactDetails')
  ).then(({ ContactDetails }) => ({
    default: ContactDetails,
  }))
)

const InvestorIdentificationPage = lazy(() =>
  lazyRetry(
    'InvestorIdentification',
    () => import(/* webpackChunkName: "InvestorIdentification" */ './pages/InvestorIdentification')
  ).then(({ InvestorIdentification }) => ({
    default: InvestorIdentification,
  }))
)

const AuthErrorPage = lazy(() =>
  lazyRetry('AuthError', () => import(/* webpackChunkName: "AuthError" */ './pages/AuthError')).then(
    ({ AuthError }) => ({
      default: AuthError,
    })
  )
)

const RegisterPage = lazy(() =>
  lazyRetry('Register', () => import(/* webpackChunkName: "Register" */ './pages/Register')).then(
    ({ Register }) => ({
      default: Register,
    })
  )
)

const SecretLinkPage = lazy(() =>
  lazyRetry('SecretLink', () => import(/* webpackChunkName: "SecretLink" */ './pages/SecretLink')).then(
    ({ SecretLink }) => ({
      default: SecretLink,
    })
  )
)

const CSSKillReactCreateAppDebugIframe = createGlobalStyle`
 body {
  iframe {
      display: none;
  }
 }`

const App = () => {
  const { isLoading } = useAuth0()

  useEffect(() => {
    utmInit()
  }, [])

  useLanguageSwitch()

  if (isLoading) {
    return <Loading />
  }

  return (
    <AppProviders>
      <RedirectHandler>
        {isDevelop && <CSSKillReactCreateAppDebugIframe />}
        <ActionsHandler />
        <Routes>
          <Route path={routes.AUTH_CALLBACK} element={<AuthCallbackPage />} />
          <Route path={routes.REGISTRATION} element={<RegistrationPage />} />
          <Route path={`${routes.REGISTER}/*`} element={<RegisterPage />} />
          <Route path={routes.CLASSIFICATION} element={<PrivateRoute component={ClassificationPage} />} />
          <Route path={routes.ACCOUNT} element={<PrivateRoute component={UserAccountPage} />} />
          <Route path={routes.CONTACT} element={<PrivateRoute component={ContactPage} />} />
          <Route path={routes.IMPRINT} element={<PrivateRoute component={ImprintPage} />} />
          <Route path={routes.AUTH_ERROR} element={<PrivateRoute component={AuthErrorPage} />} />
          <Route path={routes.ID_VERIFICATION} element={<PrivateRoute component={IdVerificationPage} />} />
          <Route path={routes.PORTFOLIO} element={<PrivateRoute component={PortfolioPage} />} />
          <Route path={routes.CONTACT_DETAILS} element={<PrivateRoute component={ContactDetailsPage} />} />
          <Route
            path={`${routes.CHECKOUT}/:productId/*`}
            element={
              <CheckoutProvider>
                <PrivateRoute component={CheckoutPage} />
              </CheckoutProvider>
            }
          />
          <Route path={routes.OVERVIEW} element={<PrivateRoute component={ProjectOverviewPage} />} />
          <Route
            path={`${routes.INVESTOR_IDENTIFICATION}/:investmentRequestId`}
            element={<PrivateRoute component={InvestorIdentificationPage} />}
          />
          <Route
            path={`${routes.PRODUCT_DETAIL}/:dealId/:name`}
            element={<PrivateRoute component={ProductDetail} />}
          />
          <Route path={routes.LOGIN} element={<Login />} />
          <Route path={`${routes.SECRET_LINK}/*`} element={<SecretLinkPage />} />
          <Route path={routes.LOGOUT} element={<Logout />} />
          <Route path={routes.PRODUCT_DETAIL_DEV} element={<BuilderDev />} />
          <Route path="*" element={<Navigate to={routes.OVERVIEW} />} />
        </Routes>
      </RedirectHandler>
    </AppProviders>
  )
}

export default App
